// src/components/Layout.js
import React,{useState,useEffect} from 'react';

import SidebarLayout from './SidebarLayout';
import Footer from './Footer';
import  "primereact/resources/themes/arya-green/theme.css";

const Layout = ({ children }) => {
    const [tokens,setToken]= useState(null);


    useEffect(() => {
        const token = localStorage.getItem('token');
        setToken(token);

    }, []);
    return (

              <div className="min-h-screen flex flex-col">
                    <main className="flex-grow">
                        {tokens &&(
                            <SidebarLayout />
                        )}
                        <div className=" w-full contents">
                            {children}
                        </div>
                    </main>
            <Footer />
        </div>
    );
};

export default Layout;
