// src/api.js
import axios from 'axios';

// Configure the default Axios instance
axios.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Create an Axios instance or use the default one
const api = axios.create();

// Add a response interceptor to the Axios instance
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      if (typeof window !== 'undefined') {
        window.location.href = '/login'; // Redirects to login page
      }
    }
    return Promise.reject(error);
  }
);

export default axios; 
export { api };
