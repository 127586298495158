// src/pages/LoginPage.js
import React, { useState,useEffect } from 'react';
import { Button, InputText, Password, Card } from 'primereact';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputField from '../../components/InputField';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const load = () => {
      setLoading(true);

      setTimeout(() => {
          setLoading(false);
      }, 2000);
  };


  useEffect(() => {
    
    // localStorage.setItem('token',"9|uNcizYdWEBr85RAasqTWNz5cKFCuQKRGTYiOSaeOe4f57c9b")
    const token = localStorage.getItem('token');

    if (token) {

      navigate('/dashboard');
    }else{
        navigate('/login');
    }

  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    // Ensure email and password are filled
    if (email && password) {
        try {
            // First, call the CSRF cookie endpoint
            await axios.get('https://api.bizipay.ng/sanctum/csrf-cookie');

            // After setting the CSRF cookie, make the POST request to your login endpoint
            const response = await axios.post('https://api.bizipay.ng/api/login', {
                email: email,
                password: password,
            });

            // Get the token from the response
            const token = response.data.token;

            // Store the token in local storage
            localStorage.setItem('token', token);

            // Redirect to the dashboard
            navigate('/dashboard');
        } catch (error) {
            // Handle errors here (e.g., show an error message)
            console.error('Login failed:', error);
            alert('Login failed: Invalid credentials');
        }
    } else {
        alert('Please enter both email and password.');
    }
};
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card title="Login" className="w-full max-w-md p-6 shadow-md">
        <form onSubmit={handleLogin}>
          <div className="p-fluid">
            <div className="p-field mb-4">
              <label htmlFor="email">Email</label>
              <InputField
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
               
                required
              />
            </div>
            <div className="p-field mb-4">
              <label htmlFor="password">Password</label>
              <InputField
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
               
             
                required
              />
            </div>
            <div className="card flex flex-wrap justify-content-center gap-3">
            <Button label="Submit" icon="" loading={loading} className="px-4 py-2 bg-green-500 text-white" onClick={handleLogin} />
            </div>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default LoginPage;
